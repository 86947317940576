import React from "react";
import './Contact.scss'
import { Element } from "react-scroll";
import axios from "axios";

export default function Contact() {
    const [getName, setName] = React.useState("");
    const [getEmail, setEmail] = React.useState("");
    const [getMessage, setMessage] = React.useState("");
    const mapKey = process.env.REACT_APP_KEY;
    console.log(mapKey, "Sdfsd");
    // const [fieldEmpty, setFieldEmpty] = false;

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Get the form data from the state or form fields
        const formData = new FormData();
        formData.append("name", getName);
        formData.append("email", getEmail);
        formData.append("message", getMessage);

        // Make a POST request to Formspree's API endpoint
        try {
            await axios.post('https://formspree.io/f/mwkjwqaw', formData);
            // Handle successful form submission
        } catch (error) {
            // Handle form submission error
        }
    };

    return (
        <Element name="contact-page">
            <div className="contacts" id="contact">
                <h1 className="title" >Contact</h1>
                <section className="contact">
                    <form
                        action="https://formspree.io/f/mwkjwqaw"
                        method="POST"
                        className="contact-form"
                        onSubmit={handleSubmit}
                    >
                        <input type="hidden" name="form-name" value="Portfolio" />
                        <h2 className="contact-form__header">
                            Hire Me
                        </h2>
                        <div className="contact-form__content">
                            <label htmlFor="name" className="contact-form__label">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                className="contact-form__name contact-form__input"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="contact-form__content">
                            <label htmlFor="email" className="contact-form__label">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                className="contact-form__email contact-form__input"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="contact-form__content">
                            <label htmlFor="message" className="contact-form__label"> Message</label>
                            <textarea
                                id="message"
                                name="message"
                                required
                                className="contact-form__message contact-form__input"
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        <button
                            type="submit"
                            className="contact-form__submit">
                            Submit
                        </button>
                    </form>

                    <div className="contact-map">
                        <div className="contact-map__wrapper">
                            <iframe
                                title="map"
                                className="contact-map__iframe"
                                src={`https://www.google.com/maps/embed/v1/place?q=v5y%202h4&key=${mapKey}`}
                            />
                            <div className="contact-info">
                                <div className="contact-info__section1">
                                    <h2 className="contact-info__title">
                                        ADDRESS
                                    </h2>
                                    <p className="contact-info__address contact__text">
                                        472 W 23rd Ave <br />
                                        Vancouver, BC V5Y 2H4
                                    </p>
                                </div>
                                <div className="contact-info__section2">
                                    <h2 className="contact-info__title">
                                        EMAIL
                                    </h2>
                                    <a className="contact-info__email contact__text" href="mailto: abc@example.com">
                                        kevin-zhang98@hotmail.com
                                    </a>
                                    <h2 className="contact-info__title">
                                        PHONE
                                    </h2>
                                    <p className="contact-info__phone contact__text">604-754-0818</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Element>
    );
}


