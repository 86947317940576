
import 'react-tooltip/dist/react-tooltip.css'
import React from "react";
import { projects } from "../../data/data";
import './Projects.scss';
import { IoArrowForwardOutline, } from "react-icons/io5";
import { FiGithub, FiVideo } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import {
  Element
} from 'react-scroll';
export default function Projects() {


  return (
    <Element name="project-page">
      <section id="projects" className="projects">
        <div className="projects__container">
          <div className="projects__header">
            <h1 className="projects__title">
              My Projects
            </h1>
            <p className="projects__description">
              Note: Please contact me and send me your GitHub ID to get access to the private repo.
            </p>

          </div>
          <div className="projects__list">
            {projects.map((project, i) => (
              <div key={project.id} className="projects__item"
              >
                <div className="projects__image">
                  <img
                    alt="gallery"
                    src={project.image}
                    className="projects__img"
                  />
                </div>
                <div className="projects__background">
                  <div className="projects__overlay">
                    <h2 className="projects__project-subtitle">
                      {project.subtitle}
                    </h2>
                    <h1 className="projects__project-title">
                      {project.title}
                    </h1>
                    {project.description &&
                      <p className="projects__project-description">
                        {project.description}
                      </p>
                    }
                    <div className="projects__links">

                      {project.git &&
                        <a className={`projects__link git${i} link-git`} target="_blank" rel="noreferrer" href={project.git}>
                          <FiGithub className='projects__icon projects__icon-git' />
                        </a>

                      }
                      <Tooltip anchorSelect={`.git${i}`} place="bottom" style={{ backgroundColor: "rgba(126,162,244)" }}>
                        Frontend and Backend: {project.status}
                      </Tooltip>

                      {project.gitFront &&
                        <a className={`projects__link front${i} link-git`} target="_blank" rel="noreferrer" href={project.gitFront}>
                          <FiGithub className='projects__icon projects__icon-git' />
                        </a>

                      }
                      <Tooltip anchorSelect={`.front${i}`} place="bottom" style={{ backgroundColor: "rgba(126,162,244)" }}>
                        Frontend: {project.status}
                      </Tooltip>

                      {project.gitBack &&
                        <a className={`projects__link back${i} link-git`} target="_blank" rel="noreferrer" href={project.gitBack}>
                          <FiGithub className='projects__icon projects__icon-git' />
                        </a>

                      }
                      <Tooltip anchorSelect={`.back${i}`} place="bottom" style={{ backgroundColor: "rgba(126,162,244)" }}>
                        Backend: {project.status}
                      </Tooltip>

                      {project.link && <a className={`projects__link link${i}`} target="_blank" rel="noreferrer" href={project.link}>
                        <IoArrowForwardOutline className='projects__icon projects__icon-website' />
                      </a>}

                      <Tooltip anchorSelect={`.link${i}`} place="bottom" style={{ backgroundColor: "rgba(126,162,244)" }}>
                        Open Website
                      </Tooltip>

                      {project.video &&
                        <a className={`projects__link video${i} link-git`} target="_blank" rel="noreferrer" href={project.video}>
                          <FiVideo className='projects__icon projects__icon-git' />
                        </a>
                      }
                      <Tooltip anchorSelect={`.video${i}`} place="bottom" style={{ backgroundColor: "rgba(126,162,244)" }}>
                        Demo Video
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section >
    </Element>
  );
}
