import 'reactjs-popup/dist/index.css';
import React from 'react';
import './Footer.scss';
import { FiGithub } from "react-icons/fi";
import { RiLinkedinFill, RiFacebookFill, RiWhatsappFill } from "react-icons/ri";
import { AiFillWechat } from "react-icons/ai";
import Popup from 'reactjs-popup';
import { Link } from "react-scroll";
function Footer() {
    return (
        <div className='footer' id="footer-page">
            <div className='footer__container'>
                <div className='footer__contact-list'>
                    <a className='footer__contact-item' target="_blank" rel="noreferrer" href="https://github.com/kai0716">
                        <FiGithub className='footer__contact-icon git' />
                    </a>
                    <a className='footer__contact-item' target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/kaijian-zhang/">
                        <RiLinkedinFill className='footer__contact-icon' />
                    </a>
                    <a className='footer__contact-item' target="_blank" rel="noreferrer" href="https://facebook.com/kevin.zhang.73550">
                        <RiFacebookFill className='footer__contact-icon' />
                    </a>


                    <Popup className='footer__contact-qr' position="right center" modal nested trigger={
                        <Link className='footer__contact-item' to='footer-page'>
                            <RiWhatsappFill className='footer__contact-icon' />
                        </Link>}
                    >
                        <img className='footer__contact-img' alt='QR' src='whatsappQR.jpg' />
                    </Popup>

                    <Popup className='footer__contact-qr' position="right center" modal nested trigger={
                        <Link className='footer__contact-item' to='footer-page'>
                            <AiFillWechat className='footer__contact-icon' />
                        </Link>}
                    >
                        <img src='qr.jpg' className='footer__contact-img' alt='QR' />
                    </Popup>
                </div >

                <div className='footer__copyright'>
                    <p>Built by Kevin Zhang</p>
                </div>
            </div >
        </div >
    )
}

export default Footer