import React from "react";
import './About.scss'
import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { Element } from 'react-scroll';
export default function About() {

  let [textLength] = useState(0);
  let [text, setText] = useState("Welcome to my portfolio website! I am a full stack developer from Vancouver, with a passion for building beautiful and functional user interfaces.");

  useEffect(() => {
    function type() {
      let textChar = text.charAt(textLength++);
      let paragraph = document.getElementById("typed");

      let charElement = document.createTextNode(textChar);
      paragraph.appendChild(charElement);
      if (textLength < text.length + 1) {
        setTimeout(type, 120);
      } else {
        setText('');
      }
    }

    type();
  }, [text, textLength]);


  return (
    <Element name="about-page">
      <section id="about" className="about">

        <div className="about__wrapper">
          <div className="about__content-1">
            <h1 className="about__title">
              Hi, I'm Kevin Zhang, <span className="about__title-position">Web Developer</span><br></br>
            </h1>
            <div className="about__detail">
              <p className="about__detail-text" id="typed">
              </p>
            </div>
            <div className="about__link">
              <Link className="about__link-contact" to="contact-page">Work With Me</Link>
              <Link className="about__link-projects" to="project-page"> See My Past Work</Link>
            </div>
          </div>
          <div className="about__content-2">
            {/* <img
            className="about__img"
            alt="about"
            src="./coding1.png"
          /> */}
          </div>
        </div>
      </section ></Element>
  );
}
