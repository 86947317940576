// import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from "react";
import { skills } from "../../data/data";
import './Skill.scss'
import { BsFillPatchCheckFill } from "react-icons/bs";
import { Element } from "react-scroll";

export default function Skill() {
  return (
    <Element name="skill-page">
      <section className="skills" id="skill">
        <div className="skills__container">
          <div className="skills__text">
            <h1 className="skills__title">
              Skills &amp; Technologies
            </h1>
            <p className="skills__description">
            </p>
          </div>
          <div className="skills__list">
            {skills.map((skill) => (
              <div key={skill} className="skills__item">
                <div className="skills__item-content">
                  <BsFillPatchCheckFill color="#10d388dc" size="1.25rem" className="skills__item-icon" />
                  <span className="skills__item-title">
                    {skill}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Element>
  );
}

