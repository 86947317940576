import { v4 as uuidv4 } from 'uuid';
export const projects = [
    {
        id: uuidv4(),
        title: "Quick Expense",
        subtitle: "React and Node",
        description:
            "A web application for users to record and store their expenses and receipt with an OCR (Tesseract.js) library to scan receipt photos and fill out the data for users.",
        image: "./project-1.png",
        gitFront: "https://github.com/kai0716/Capstone-Quick-Expense-client",
        gitBack: "https://github.com/kai0716/Capstone-Quick-Expense-server",
        status: "Public",

    },
    {
        id: uuidv4(),
        title: "Portfolio",
        subtitle: "React",
        image: "./project-3.png",
        gitFront: "https://github.com/kai0716/kai-portfolio",
        link: "/",
        status: "Public"
    },
    {
        id: uuidv4(),
        title: "Project Management System",
        subtitle: "Java, JavaScript,and Spring Boot",
        description:
            "A project managment web application for university CS students to plan and manage their dessertation project and stay connect with their suppervisor.",
        image: "./project-7.png",
        git: "https://github.com/kai0716/Birmingham-individual-Project",
        video: "https://www.loom.com/share/5442bbeef25a4c72a98d036c2555825d",

        status: "Public"
    },
    {
        id: uuidv4(),
        title: "Instock",
        subtitle: "React and Node",
        description:
            "A Warehouse Management System Group Project. Built by Kevin, Aya, Matthrew, Henry",
        image: "./project-6.png",
        gitFront: "https://github.com/kai0716/instock-client",
        gitBack: "https://github.com/kai0716/instock-server",
        status: "Private",
        video: "./instock.mp4"
    },
    {
        id: uuidv4(),
        title: "BrainFlix",
        subtitle: "React and Node",
        description:
            "A responsive prototype for a new video streaming platform without using database",
        image: "./project-2.png",
        gitFront: "https://github.com/kai0716/kaijian-zhang-brainflix",
        gitBack: "https://github.com/kai0716/kaijian-zhang-brainflix-api",
        link: "https://symphonious-bonbon-f2acf3.netlify.app/",
        status: "Private"
    },
    {
        id: uuidv4(),
        title: "Telus TV Package",
        subtitle: "React",
        description:
            "A 24 hours group hackerthon frontend project from Telus. The task was to design a new website that helps Telus's customers get what they want out of their TV package.",
        image: "./project-4.png",
        gitFront: "https://github.com/kai0716/telus_client",
        link: "https://famous-truffle-d05636.netlify.app",
        status: "Public",
    },
    {
        id: uuidv4(),
        title: "TravelSite",
        subtitle: "HTML CSS",
        description:
            "A simple frontend focus travel website built in HTML and CSS",
        image: "./project-5.png",
        gitFront: "https://github.com/kai0716/kaijian-zhang-travelsite",
        link: "https://unrivaled-crisp-7fdfce.netlify.app",
        status: "Private"
    },
    {
        id: uuidv4(),
        title: " School Online Exam Application",
        subtitle: "Java, JavaScript,and Spring Boot",
        description:
            "An exam web application that allows students to take exams online and support teachers to create and mark exams.",
        image: "./project-8.png",
        git: "https://github.com/kai0716/BSc-Individual-Spring-Project",
        status: "Public",
    },
];

export const skills = [
    "JavaScript",
    "Java",
    "React",
    "Express",
    "Node",
    "MySQL",
    "HTML",
    "CSS",
    "SCSS",
    "Git",
    "Spring Boot",
    "React Native: (Learning)"
];
