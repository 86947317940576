import './Header.scss';
import { useState, useEffect } from 'react';
import { Link } from "react-scroll";

export default function Header(props) {

    const [open, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(true)
    const setIcon = () => {
        setOpen(!open);
    }
    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 767) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    useEffect(() => {
        if (!isMobile) {
            setOpen(false);
        }

    }, [isMobile])


    return (
        <header className={`${open ? 'background-thick' : ''} app-header`} >

            <nav className=" header-nav navbar navbar-expand-lg navbar-light rounded">
                <div className='header-nav__wrapper'>
                    <a className="header-nav__name" href="/">
                        <span className='header-nav__fname'>KEVIN</span> <span className='header-nav__lname'>ZHANG</span>
                    </a>

                    <div className='menu-icon__wrapper'>
                        <div className="menu-icon" onClick={() => setIcon()}>
                            <div className={`menu-icon__bar1 ${open ? 'change1' : ''}`} />
                            <div className={`menu-icon__bar2 ${open ? 'change2' : ''}`} />
                            <div className={`menu-icon__bar3 ${open ? 'change3' : ''}`} />
                        </div>
                    </div>
                </div>
                <>
                    <div className={`navbar-collapse`}>
                        <ul id="menu" className={`${open ? "show" : "not-show"} header-nav__link-wrapper header-nav__collapse`}>
                            <li className="header-nav__li">
                                <Link className="header-nav__li-link colapse-link l1" activeClass="active" spy={true} to="about-page">Home</Link>
                            </li>
                            <li className="header-nav__li">
                                <Link className="header-nav__li-link colapse-link l2" activeClass="active" spy={true} to="project-page">Projects</Link>
                            </li>
                            <li className="header-nav__li">
                                <Link className="header-nav__li-link colapse-link l3" activeClass="active" spy={true} to="skill-page">Skills</Link>
                            </li>
                            <li className="header-nav__li">
                                <Link className="header-nav__li-link colapse-link l4" activeClass="active" spy={true} to="contact-page">Contact</Link>
                            </li>
                        </ul>
                    </div>

                    <ul className="header-nav__link-wrapper header-nav__link-wrapper-tablet">
                        <li className="header-nav__li">
                            <Link className="header-nav__li-link l1" activeClass="active" spy={true} to="about-page">Home</Link>
                        </li>
                        <li className="header-nav__li">
                            <Link className="header-nav__li-link l2" activeClass="active" spy={true} to="project-page" >Projects</Link>
                        </li>
                        <li className="header-nav__li">
                            <Link className="header-nav__li-link l3" activeClass="active" spy={true} to="skill-page">Skills</Link>
                        </li>
                        <li className="header-nav__li">
                            <Link className="header-nav__li-link l4" activeClass="active" spy={true} to="contact-page">Contact</Link>
                        </li>
                    </ul>
                </>
            </nav>

        </ header >

    );
}