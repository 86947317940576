import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function ParticleComponent(props) {
    const { direction, amount } = props;
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);


    return <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={
            {
                background: {
                    // color: {
                    //     value: "#0d47a1",
                    // },
                },
                fpsLimit: 120,
                // fullScreen: {
                //     enable: false
                // },
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "repulse",
                        },
                        onHover: {
                            enable: true,
                            mode: "bubble",
                        },
                        resize: true,
                    },
                    modes: {
                        repulse: {
                            distance: 50,
                            duration: 0.3
                        },
                        bubble: {
                            distance: 83.91608391608392,
                            size: 2,
                            duration: 3,
                            opacity: 2,
                            speed: 3
                        },
                    },

                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: false,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        direction: `${direction}`,
                        enable: true,
                        outModes: {
                            default: "out",
                        },
                        random: true,
                        speed: .88,
                        straight: true,
                        attract: {
                            enable: false,
                            rotateX: 600,
                            rotateY: 1200
                        }

                    },
                    number: {
                        density: {
                            enable: false,
                            area: 1800,
                        },
                        value: `${parseInt(amount)}`,

                    },
                    opacity: {
                        value: 0.5,
                        random: false,
                        anim: {
                            enable: true,
                            speed: 0.5,
                            opacity_min: 0,
                            sync: false
                        }
                    },
                    shape: {
                        type: "circle",
                    },

                    size: {
                        value: { min: 1, max: 2 },
                    },


                },
                detectRetina: true,
            }
        }
    />
}
export default ParticleComponent;