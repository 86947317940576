import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Project/Projects';
import Skill from './components/Skill/Skill';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import ParticleComponent from "./components/Particles/Particles";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollSpy from "react-ui-scrollspy";

function App() {
  const [isMobile, setIsMobile] = useState();

  // Check if it is in mobile size
  const handleResize = () => {
    if (window.innerWidth < 767) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  return (
    <div className="App">
      {isMobile ? <ParticleComponent direction="bottom" amount="200" /> : <ParticleComponent direction="left" amount="800" />}

      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <ScrollSpy>
          <About />
          <Projects />
          <Skill />
          <Contact />
        </ScrollSpy>
        <Footer />

      </BrowserRouter>

    </div>
  );
}

export default App;
